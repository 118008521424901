import { TypographyFontWeight, TypographyVariant } from './types'

/**
 * Font families in theme
 */
export const getTypographyFont = (variant: TypographyVariant) => `var(--typography-${variant})`

export const getTypographyLetterSpacing = (variant: TypographyVariant) =>
  `var(--typography-${variant}-letter-spacing)`

export const getTypographyTextTransform = (variant: TypographyVariant) =>
  `var(--typography-${variant}-text-transform)`

const weightNameToNumber: Record<TypographyFontWeight, number> = {
  normal: 400,
  medium: 500,
  'semi-bold': 600,
  heavy: 800,
}

export const getTypographyFontWeight = (weight: TypographyFontWeight) => weightNameToNumber[weight]
